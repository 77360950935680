$(function() {
    var menuButton = $(".js-mobile-menu");
    var mobileMenu = $(".js-checked-menu");
    var humburger = $(".js-mobile-menu-toggler");
    var submenu = $(".js-submenu");
    var submenuButton = $(".js-submenu-button");

    menuButton.on("click", function(event) {
        event.preventDefault();

        var elementClick = $(this);
        var selector = $(this).attr("data-menu");
        var menuList = $(".js-menu-list");
        menuList.css("display", "flex");

        mobileMenu.slideUp(500);
        menuButton.removeClass("button-mobile--active");
        elementClick.addClass("button-mobile--active");

        humburger.removeClass("active");

        if ($("." + selector).is(":hidden")) {
            $("." + selector)
                .slideDown(500)
                .addClass("open")
                .addClass("check");
            elementClick.addClass("button-mobile--active");
            elementClick.addClass("active");
        } else {
            $("." + selector)
                .slideUp(500)
                .removeClass("open")
                .removeClass("check");

            setTimeout(function(event) {
                elementClick.removeClass("button-mobile--active");
            }, 500);
        }

        event.stopPropagation();
    });

    $(document).on("click", function(event) {
        if ($(event.target).closest(menuButton).length) return;
        if ($(event.target).closest(mobileMenu).length) return;

        if ($(window).width() <= "1100") {
            mobileMenu
                .slideUp(500)
                .removeClass("open")
                .removeClass("check");
            setTimeout(function(event) {
                menuButton.removeClass("button-mobile--active");
            }, 500);
            humburger.removeClass("active");

            event.stopPropagation();
        }
        event.stopPropagation();
    });

    function windowSizeMenu() {
        if ($(window).width() >= "1100") {
            menuButton.removeClass("button-mobile--active");
            mobileMenu.removeAttr("style").removeClass("open");
            humburger.removeClass("active");
            submenu.removeAttr("style");
			submenuButton.removeClass("menu__down--active");
        }
    }

    windowSizeMenu();
    $(window).on("resize", windowSizeMenu);
});
