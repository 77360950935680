$(function () {

    if (typeof jsonImg !== 'undefined') {
        var urlImages = jsonImg;

        //Adding block for to insert image
        $("#wrap_Inputfield_headset").after("<div class='form__group' id='image_headset' >"+
                                                "<div class='form__field form--field-style'><img src='/site/assets/files/1033/c1.png'></div>"+
                                            "</div>");                                        
        
        $("#Inputfield_headset").bind('click', function() {
    
            var imgUrl;
    
            for (var key in urlImages) {
                if (this.value == key) {  
                    imgUrl = urlImages[key];
                    
                    if (imgUrl != null) {
                        addHtmlElement(imgUrl);
                    }
                }
            }
        });
    
        function addHtmlElement(url){
            if (url != null) {
                $("#image_headset").html("<div class='form__field form--field-style'><img src='" + url + "'></div>");
            }
        }
    }

    $(".InputfieldFormBuilderFileList").css({'min-width':'560px'});
    $(".InputfieldFormBuilderFileUpload").addClass('form__file form__field-file');

    $(".InputfieldFormBuilderFileList").append('<div class="form__field form--field-style"><a href="#" class="form__btn-add js-add-file">Добавить файл</a></div>');

    $(".InputfieldFormBuilderFileUpload:not(:first)").hide();
    $(".InputfieldFormBuilderFileUpload").append('<a href="#" class="js-remove"><img src="/site/templates/static/img/calculator/delete.png"></a>');

    
    var addFile = ".js-add-file";
    var removeFile = ".js-remove";

    var i = 0;
    
    $("html").on("click", addFile, function(event) {
        event.preventDefault();
        if (i < 9) {
            i++;
            $(".InputfieldFormBuilderFileUpload:eq(" + i + ")").show();
        }
        event.stopPropagation();
    });

    $("html").on("click", removeFile, function(event) {
        event.preventDefault();
        $(".InputfieldFormBuilderFileUpload:eq(" + i + ")").hide();
        i--;
        event.stopPropagation();
    });    

});