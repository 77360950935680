$(function() {
    var addFile = ".js-add-file";
    var remove = ".js-remove";

    var i = $(".js-field").length;

    $("html").on("click", addFile, function(event) {
        event.preventDefault();
        $(
            '<div class="js-field form__field-file">' +
                '<input class="form__file js-input-file" ' +
                ' name="drawing-' +
                i +
                '[]" type="file">' +
                '<a href="#" class="js-remove"><img src="/img/calculator/delete.png"></a>' +
                "</div>"
        ).appendTo(".js-append-input");
        i++;
        event.stopPropagation();
    });

    $("html").on("click", remove, function(event) {
        event.preventDefault();
        if (i > 1) {
            $(this)
                .parents(".js-field")
                .remove();
            i--;
        }
        event.stopPropagation();
    });
});
