$(function() {
	var submenuButton = $(".js-submenu-button");
	var submenu = $(".js-submenu");

	submenuButton.on("click", function() {
		if ($(this).hasClass("menu__down--active")) {
			submenu.slideUp(500);
			$(this).removeClass("menu__down--active");
		} else {
			submenu.slideUp(500);
			$(this)
				.toggleClass("menu__down--active")
				.next()
				.slideToggle(500);
		}
	});
});
