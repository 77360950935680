$(function() {
    var downDrop = $("#select-type");
    var scheme_1 = $(".form__direct");
    var scheme_2 = $(".form__corner-right");
    var scheme_3 = $(".form__couner-left");
    var scheme_4 = $(".form__curved");

    function switchType(status) {
        scheme_1.hide();
        scheme_2.hide();
        scheme_3.hide();
        scheme_4.hide();
        switch (status) {
            case "corner-right":
                scheme_2.show();
                break;
            case "couner-left":
                scheme_3.show();
                break;
            case "curved":
                scheme_4.show();
                break;
            default:
                scheme_1.show();
        }
    }

    switchType(downDrop.val());

    downDrop.on("change", function() {
        switchType(downDrop.val());
    });
});
